/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import pessoa from '../../assets/pessoa.svg';
import empresa from '../../assets/empresa.svg';
import chatinfo from '../../assets/chatinfo.svg';
import { Container } from './styles';
import { Data } from '../../pages/RequestForm';
import { dateFullToString } from '../../utils/dateConversion';
import { callNurseApi } from '../../services/api';
import { Button } from '../Button';
import BackArrowIcon from '../../assets/arrow.svg';
import { useAuth } from '../../hooks/useAuth';
import { capitalizeString } from '../../utils/stringFormat';

interface IInformacoesModal{
  info : Data;
  cdAtendimento: number | undefined;
  CloseModal: () => void,
  BuscarAtendimentoInfo: (cdAtendimento: number | undefined) => void,
}
export default function Informacoes({ info,
  cdAtendimento,
  CloseModal,
  BuscarAtendimentoInfo,
}:IInformacoesModal) {
  const navigate = useNavigate();
  const { cdUsuario, permissions, associatedBusinesses } = useAuth();

  const chatPermission = permissions.find(item => item.idPermissao === 'atendimento').editar && associatedBusinesses.find(item => item.cdEmpresa === info.cdGrupo);
  console.log({ chatPermission });

  function handleSpan() {
    navigate('/requests');
  }
  const idUsuario = sessionStorage.getItem('id');

  // eslint-disable-next-line no-shadow
  async function IniciarAtendimento(cdAtendimento: number | undefined) {
    const iniciarResponse = await callNurseApi.put('/atendimento/start', { cdUsuario: idUsuario, cdAtendimento });

    if (iniciarResponse?.data?.status) {
      toast.success('Atendimento iniciado');
      BuscarAtendimentoInfo(cdAtendimento);
      CloseModal();
    } else {
      toast.error(iniciarResponse?.data?.message || 'Falha ao iniciar atendimento.');
    }
  }
  return (

    <Container>
      <div id="title">
        <button type="button" id="backButton" onClick={() => handleSpan()}>
          <img src={BackArrowIcon} alt="Retornar para a página anterior" />
        </button>
        <h1>Dados da Chamada</h1>
        <div />
      </div>
      <section id="solicitante">
        <div>
          <img
            src={pessoa}
            id="pessoa"
            alt="cliente"
          />
          <h2>Solicitante</h2>
        </div>
        <div id="divCnpj">
          <h3 className="subTitle">CNPJ:</h3>
          <h3 id="cnpj">{info.cnpjCliente}</h3>
        </div>
        <div id="divRazao">
          <h3 className="subTitle">Razão Social:</h3>
          <h3>{capitalizeString(info.razaoSocialCliente)}</h3>
        </div>
        <div id="divSoli">
          <h3 className="subTitle">Solicitante:</h3>
          <h3 id="solicitante">{info.nomeCliente}</h3>
        </div>
        <div id="divSoli">
          <h3 className="subTitle">Email:</h3>
          <h3 id="solicitante">{info.emailCliente}</h3>
        </div>
      </section>

      <section>
        <div id="titleEmpresa">
          <img
            src={empresa}
            id="empresa1"
            alt="empresa prestadora"
          />
          <h2>Empresa Prestadora</h2>
        </div>
        <div id="empresa">
          <h3 className="subTitle">Razão Social:</h3>
          <h3>{info.nomeGrupo}</h3>
        </div>
      </section>

      <section>
        <div id="DadosAtendimento">
          <img
            src={chatinfo}
            id="chatbubble"
            alt="Dados do atendimento"
          />
          <h2>Dados do Atendimento</h2>
        </div>
        <div id="divNivel">
          <h3 className="subTitle">Nível:</h3>
          <h3 id="nivel">{info.nomeNivelCriticidade}</h3>
        </div>
        <div id="divData">
          <h3 className="subTitle">Data:</h3>
          <h3 id="data">{dateFullToString(new Date(info.dataAbertura))}</h3>
        </div>
      </section>
      <Button
        disabled={!chatPermission}
        id="button"
        onClick={() => {
          IniciarAtendimento(cdAtendimento);
        }}
      >Iniciar Chamada
      </Button>
    </Container>

  );
}

import React, { useCallback, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal } from '../../components/Modal';
import { getValidationErrors } from '../../utils/getValidationError';

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import logoImage from '../../assets/bunzl-saude-image.png';

import { Container, Content, Background } from './styles';
import { callNurseApi } from '../../services/api';

const schema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'No mínimo 6 digitos'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
});

export const NewPassword: React.FC = () => {
  const { token } = useParams();

  const formRef = useRef<FormHandles>(null);
  const [showForm, setShowForm] = useState(false);

  const messages = {
    sucess: {
      title: 'Senha alterada com sucesso',
      text: 'Faça o login para continuar.',
    },
    failure: {
      title: 'Erro ao enviar a senha.',
      text: 'Por favor preencha o formulário de recuperação novamente.',
    },

  };

  const handleSubmit = useCallback(async (data: any) => {
    try {
      formRef.current?.setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      const response = await callNurseApi.put('/usuario/changePasswordToken', { token: token || data.token, senha: data.password });
      if (!response) {
        toast.error('Token inváldio');
      } else {
        setShowForm(true);
      }
    } catch (err) {
      const errors = getValidationErrors(err);
      formRef.current?.setErrors(errors);
    }
  }, []);

  return (
    <Container>
      <Background />
      <Content>
        <img src={logoImage} alt="LOGO EMPRESA" />

        <Form
          ref={formRef}
          initialData={{ name: '' }}
          onSubmit={handleSubmit}
        >

          <div id="welcome">
            <h1>Cadastro de senha</h1>
            <h4>Por favor, informe sua nova senha.</h4>
          </div>
          {
            !token && (
            <Input
              name="token"
              placeholder="Token"
            />
            )
}

          <Input
            name="password"
            placeholder="Senha"
            type="password"
          />

          <Input
            name="passwordConfirmation"
            placeholder="Confirme a senha"
            type="password"
          />

          <Button type="submit" onSubmit={handleSubmit}>Recuperar senha</Button>
        </Form>
        {/*  </Form> */}

      </Content>
      <Modal showForm={showForm} setShowForm={setShowForm} message={messages.sucess} />
    </Container>
  );
};

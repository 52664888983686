import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Input } from '../../components/InputDown';
import { Table } from '../../components/Table/index.js';
import { callNurseApi } from '../../services/api';
import { clientsFields } from '../../utils/searchFields';
import { Title } from '../../components/Title';
import { capitalizeString } from '../../utils/stringFormat';

interface Data {
    IdInterno: number,
    cdEmpresa: number,
    Status: number,
    razaoSocial: string,
    CNPJ:string,
    Telefone?: string,
    Email?: string,
    Cidade?: string,
    Nome?: string,
    Tipo?: string,
    cdTipoEmpresa: number;
}

export const Clients: React.FC = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'cdEmpresa', // accessor is the "key" in the data
        Filter: Input,
        filter: 'fuzzyText',
        width: 100,
      },
      {
        Header: 'Razão Social',
        accessor: 'razaoSocial',
        Filter: Input,
        filter: 'fuzzyText',
        Cell: ({ cell: { value } }: any) => (value ? capitalizeString(value.replace(/_/g, ' ')) : 'Indisponível'),
      },
      {
        Header: 'CNPJ',
        accessor: 'cnpj',
        Filter: Input,
        filter: 'fuzzyText',
        Cell: ({ cell: { value } }: any) => <span>{ value || 'Indisponível' }</span>,
      },
      {
        Header: 'Tipo de empresa',
        accessor: 'cdTipoEmpresa',
        Filter: Input,
        filter: 'fuzzyText',
        width: 300,
        Cell: ({ cell: { value } }: any) => <span>{ value || 'Indisponível' }</span>,
      },

      {
        Header: 'Ação',
        accessor: 'IdInterno',
        width: 50,
        Cell: ({ cell: { value } }: any) => (
          <Link to={{ pathname: `/client-form/${value}` /* state: { value } */ }}>
            <button type="button">
              Ver
            </button>
          </Link>
        ),
      },

    ],
    [],
  );

  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    async function Empresas() {
      try {
        const response = await callNurseApi.post('/empresa/select', { pageNumber: 1, pageCount: 500 });
        const EmpresasFormatadas = response.data.empresas
          .map((Emp : Data) => ({ ...Emp, IdInterno: Emp.cdEmpresa }));

        setData(EmpresasFormatadas);
      } catch {
        console.warn('Erro ao buscar dados');
      }
    }

    Empresas();
  }, []);

  return (
    <>
      <Helmet>
        <title>Bunzl | Empresa</title>
      </Helmet>
      <Title title="Empresa" />
      <Table columns={columns} data={data} fields={clientsFields} />
    </>
  );
};

import { useNavigate, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Container, Content } from './styles';
import { useSocket } from '../../hooks/useSocket';
import { callNurseApi } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';

export function ChatInvite() {
  const {
    socket,
    invitationInfo,
    setInvitationInfo,
    invitationResponse,
    setInvitationResponse,
  } = useSocket();
  const { cdUsuario } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  async function handleAcceptInvitation() {
    try {
      const { data } = await callNurseApi.put('/atendimento/addAttendant', {
        cdUsuarioAtendente: invitationInfo.cdUsuario,
        cdUsuarioAuxiliar: cdUsuario,
        cdAtendimento: invitationInfo.cdAtendimento,
      });
      if (data.status) {
        if (location.pathname.includes('requests-form')) {
          console.log('location', location.pathname);
          navigate('/requests', { replace: true });
        }
        navigate(`/requests-form/${invitationInfo.cdAtendimento}`, { replace: true });
      } else throw new Error(data.message);
    } catch (e) {
      toast.error(e.message);
    } finally {
      setInvitationInfo(null);
    }
  }

  function handleRefuseInvitation() {
    setInvitationInfo(null);

    socket!.emit('invitation response', {
      cdAtendimento: invitationInfo.cdAtendimento,
      from: { cdUsuario },
      to: { cdUsuario: invitationInfo.cdUsuario },
      accepted: false,
    });
  }

  function handleCloseInvitationResponse() {
    setInvitationResponse(null);
  }

  if (invitationInfo) {
    return (
      <Container>
        <Content>
          <div id="box">
            <div id="title">
              <span>Convite de atendimento</span>
            </div>
            <div id="content">
              {invitationInfo?.nome} te convidou para a chamada
              #{invitationInfo?.cdAtendimento}.

              <div id="buttons">
                <Button
                  onClick={() => handleAcceptInvitation()}
                >
                  Aceitar
                </Button>
                <Button
                  refuse
                  onClick={() => handleRefuseInvitation()}
                >
                  Recusar
                </Button>
              </div>
            </div>

          </div>
        </Content>
      </Container>
    );
  }

  if (invitationResponse?.accepted === false) {
    return (
      <Container>
        <Content>
          <div id="box">
            <div id="title">
              <span>Convite de atendimento</span>
            </div>
            <div id="content">
              Usuário número {invitationResponse?.to.cdUsuario} recusou o seu convite.
              <div id="buttons">
                <Button
                  onClick={() => handleCloseInvitationResponse()}
                >
                  Fechar
                </Button>
              </div>
            </div>

          </div>
        </Content>
      </Container>

    );
  }

  return null;
}

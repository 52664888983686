/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-body-style */
import { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callNurseApi } from '../../services/api';

import { Content, TabLink } from './styles';
import { UserInfo } from '../../components/UserInfo';
import { IBusiness, IPermission, UserPermissions } from '../../components/UserPermissions';
import { Title } from '../../components/Title';
import { UserEmpresa } from '../../components/userEmp';
import { useAuth } from '../../hooks/useAuth';
import { SelectBusinessDTO } from '../../DTO/Request/SelectBusiness';
import { SelectPermissionsDTO } from '../../DTO/Request/SelectPermissions';

export interface Usuario {
  nome: string;
  email: string;
  Funcionalidade: any;
  cdUsuario: number;
  CdFuncionalidade: number;
  Tipo: number
  CdEmpresa: number;
  Empresa: any;
  cdUsuarioAlteracao: string;

}

export function UserForm() {
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams();
  const { pathname } = useLocation();
  const [activePage, setActivePage] = useState(1);
  const { cdUsuario } = useAuth();

  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);

  const [permissionsList, setPermissionsList] = useState<SelectPermissionsDTO[]>();
  const [businessesList, setBusinessesList] = useState<SelectBusinessDTO[]>();

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [usuario, setUsuario] = useState<Usuario>({} as Usuario);
  const [checkEmp, setCheckEmp] = useState<Usuario[]>([]);
  const tabs: {[key: number]: any} = {
    0: () => (
      <UserPermissions
        permissions={permissions}
        setPermissions={setPermissions}
        businesses={businesses}
        setBusinesses={setBusinesses}
        businessesList={businessesList}
        permissionsList={permissionsList}
      />
    ),
    1: () => (

      <UserInfo
        nome={nome}
        setName={setNome}
        email={email}
        setEmail={setEmail}
      />
    ),
    2: () => (
      <UserEmpresa
        checkEmp={checkEmp}
        setCheckEmp={setCheckEmp}
        userId={usuario.cdUsuario}
      />
    ),
  };

  const getUser = async () => {
    try {
      const { data } = await callNurseApi.get(`/usuario/${id}`);
      if (data.status) {
        setNome(data.usuario.nome);
        setEmail(data.usuario.email);
        setPermissions(data.usuario.permissoes.map(item => {
          return {
            idPermissao: item.idPermissao,
            visualizar: item.visualizar || false,
            editar: item.editar || false,
          };
        }));
        setBusinesses(data.usuario.empresas.map(item => { return { cdEmpresa: item.cdEmpresa }; }));
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  const getBusiness = async () => {
    try {
      const { data } = await callNurseApi.post('/empresa/select', {
        cdTipoEmpresa: 1,
        pageNumber: 1,
        pageCount: 999,
      });
      if (data.status) {
        setBusinessesList(data.empresas);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  const getPermissions = async () => {
    try {
      const { data } = await callNurseApi.get('/permissao/all');
      if (data.status) {
        setPermissionsList(data.permissoes);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  };

  async function handleSubmit() {
    try {
      const formattedResponse = {
        cdUsuario: Number(id),
        nome: nome || usuario.nome,
        email: email || usuario.email,
        permissoes: permissions,
        empresas: businesses,
        cdUsuarioAlteracao: cdUsuario,
      };

      const response = await callNurseApi.put('/usuario/', formattedResponse);
      if (response.data.status) {
        toast.success('Usuário alterado com sucesso');
        getUser();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    Promise.all([getUser(), getBusiness(), getPermissions()]);
  }, []);

  return (
    <Content>
      <Title title="Usuários" backButton />
      <nav>
        <TabLink
          to={pathname}
          onClick={() => setActivePage(1)}
          active={activePage === 1}
        >
          Informações
        </TabLink>
        <TabLink
          onClick={() => setActivePage(0)}
          to={pathname}
          active={activePage === 0}
        >
          Permissões
        </TabLink>
        {/* <TabLink
          onClick={() => setActivePage(2)}
          to={pathname}
          active={activePage === 2}
        >
          Empresas relacionadas
        </TabLink> */}
      </nav>

      <Form
        ref={formRef}
        onSubmit={handleSubmit}
      >
        {tabs[activePage]()}
      </Form>
    </Content>
  );
}

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Chart } from '../Chart';

import { Container, DataBox, TypeButton } from './styles';
import { callNurseApi } from '../../services/api';
import { useAuth } from '../../hooks/useAuth';
import { NewSelect } from '../NewSelect';

type DailyStars = {
  date: Date,
  stars: number,
}
export interface IDiaSemana{
  atendimentos: number;
  nomeDiaSemana: string;
  numeroDiaSemana: number;
  hoje: boolean;
  dtDiaSemana: string;
}

interface DashboardResponse {
  dashboard: {
    atendimentosTotal: number;
    atendimentosUsuario: number
    atendimentosSemanal: IDiaSemana[];
  }
}
export function DashboardData() {
  const { associatedBusinesses } = useAuth();
  const [dashboardData, setDashboardData] = useState<DashboardResponse>({} as DashboardResponse);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [selectedEmpresa, setSelectedEmpresa] = useState<number | null>(null);
  const [selectedType, setSelectType] = useState<number | null>(0);
  const [options, setOptions] = useState([]);
  const [optionsEmpresa, setOptionsEmpresa] = useState();

  async function fetchData() {
    try {
      const { data } = await callNurseApi.post('/atendimento/dashboard', {
        cdGrupo: associatedBusinesses.length <= 1
          ? associatedBusinesses[0].cdEmpresa : selectedEmpresa,
        cdAtendente: selectedUser,
      });
      setDashboardData(data);

      setLoading(false);
      if (!data.status) throw Error;
    } catch {
      toast.error('Erro ao buscar dados.');
    }
  }
  async function BuscarUsuarios() {
    try {
      const { data } = await callNurseApi.get(`/usuario/${(associatedBusinesses.length <= 1 ? `?cdgrupo=${associatedBusinesses[0].cdEmpresa}` : '')}`);
      setOptions(data.usuarios.map(item => ({
        value: item.cdUsuario,
        message: item.nome,
      })));
    } catch {
      toast.error('Erro ao buscar as opções.');
    }
  }

  async function BuscarEmpresas() {
    try {
      const { data } = await callNurseApi.post('/empresa/select', {
        cdTipoEmpresa: 1,
        pageNumber: 1,
        pageCount: 999,
      });
      setOptionsEmpresa(data.empresas.map(item => ({
        value: item.cdEmpresa,
        message: item.razaoSocial,
      })));
    } catch {
      toast.error('Erro ao buscar as opções.');
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
    BuscarUsuarios();
  }, [selectedUser]);

  useEffect(() => {
    fetchData();
    BuscarEmpresas();
  }, [selectedEmpresa]);

  useEffect(() => {
    setSelectedEmpresa(null);
    setSelectedUser(null);
  }, [selectedType]);

  return (
    loading ? <p>Carregando...</p> : (
      <Container>
        <div id="ButtonContainer">
          <TypeButton
            onClick={() => setSelectType(0)}
            selected={selectedType === 0}
          >
            Total
          </TypeButton>
          <TypeButton
            onClick={() => setSelectType(1)}
            selected={selectedType === 1}
          >
            Por empresa parceira
          </TypeButton>
          <TypeButton
            onClick={() => setSelectType(2)}
            selected={selectedType === 2}
          >
            Por enfermeiro
          </TypeButton>
        </div>
        {selectedType === 2 && (
          <NewSelect
            options={options}
            onSelect={setSelectedUser}
            selected={selectedUser}
            defaultMessage="Selecionar Enfermeiro"
          />
        )}
        {selectedType === 1 && (
        <NewSelect
          options={optionsEmpresa}
          onSelect={setSelectedEmpresa}
          selected={selectedEmpresa}
          defaultMessage="Selecionar Empresa"
        />
        )}
        <DataBox>
          <div>
            <p className="category">Atendimentos Realizados</p>
            <p className="data">{dashboardData?.dashboard.atendimentosTotal || 'Não informado'}</p>
          </div>
          {selectedType === 2 && (
            <div>
              <p className="category">Atendimento do enfermeiro </p>
              <p className="data">{selectedUser ? dashboardData?.dashboard.atendimentosUsuario : '-'}</p>
            </div>

          )}
        </DataBox>

        <Chart weeklyRequests={dashboardData?.dashboard.atendimentosSemanal} />

      </Container>
    )
  );
}

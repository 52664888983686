/* eslint-disable arrow-body-style */
import { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { callNurseApi } from '../../services/api';
import { messages } from '../../utils/statusMessage';

import { Title } from '../../components/Title';
import { Input } from '../../components/Input';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';

import { Content } from './styles';

export interface IFaq {
  titulo: any;
  conteudo: any;
  ordem: number;
  ativo: boolean;
  cdUsuarioInclusao: string;
}

export function CreateFaq() {
  const formRef = useRef<FormHandles>(null);
  const [titulo, setTitulo] = useState('');
  const [conteudo, setConteudo] = useState('');
  const [link, setLink] = useState('');
  const [ativo, setAtivo] = useState(false);

  const id = sessionStorage.getItem('id');
  const navigate = useNavigate();
  let response:any;

  const handleSubmit = async () => {
    try {
      const corpo = { titulo,
        conteudo,
        ordem: 100,
        ativo,
        cdUsuarioInclusao: id,
        link,
      };
      response = await callNurseApi.post('/faq/', corpo);

      if (response?.data.status) {
        toast.success('FAQ criado com sucesso!');
        setTitulo('');
        setConteudo('');
        setLink('');
        setAtivo(false);
        navigate('/faqs');
      }
    } catch (error) {
      toast.error('Erro ao criar FAQ!');
    }
  };

  return (
    <Content>
      <Title title="Gerenciamento de FAQ" backButton />
      <h2>Criar</h2>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div id="faq-info">
          <Select
            name="Ativo"
            label="Status"
            id="Ativo"
            placeholder=""
            options={messages.ativo}
            onChange={({ value }) => setAtivo(value)}
          />
          <Input
            name="titulo"
            label="Título"
            value={titulo}
            onChange={(event) => setTitulo(event.target.value)}
          />
          <Input
            name="messageItem"
            placeholder="http://"
            label="Link"
            type="url"
            value={link}
            onChange={(event) => setLink(event.target.value)}
          />
        </div>

        <p>Resposta</p>
        <textarea
          id="messageItem"
          spellCheck="false"
          value={conteudo}
          onChange={(event) => setConteudo(event.target.value)}
        />
        <div id="button">
          <Button type="submit">Adicionar</Button>
        </div>
      </Form>
    </Content>
  );
}

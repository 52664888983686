/* eslint-disable no-shadow */
/* eslint-disable no-undef */
import { useState, createContext, useContext, useEffect, Dispatch, SetStateAction } from 'react';
import { io, Socket } from 'socket.io-client';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { IMensagem } from '../pages/RequestForm';
import { variables } from '../configuration/Constants';
import { useAuth } from './useAuth';
import { callNurseApi } from '../services/api';
import { GetUserByIdDTO } from '../DTO/Response/GetUserById';

interface IEnterChat{
    cdAtendimento: number;
    cdUsuario: number
}

type NovosAtendimentos = {atendimentos:number[]} & {count: number}

interface ISocketVideoMessage {
  offer: string;
  cdUsuario?: number;
  cdCliente?: number;
}

interface IVideoAction {
  cdAtendimento?: number;
  cdUsuario?: number;
  cdCliente?: number;
  muted?: boolean;
  hidden?: boolean;
  request?: boolean;
}

export interface ISignal{
  cdCliente?: number;
  cdUsuario?: number;
  cdAtendimento: number;
}
interface ISocketIdentifier{
  socketId: string,
  cdCliente?: number,
  cdUsuario?: number
}

interface IOnlineUsers {
  users: ISocketIdentifier
}

export interface TargetedAction {
  cdAtendimento: number;
  from: ISender;
  to: ISender;
}

interface ISender{
  cdUsuario?: number;
  cdCliente?: number;
}

interface InvitationResponse extends TargetedAction {
  accepted: boolean;
}

interface IOnlineUser {
  socketId: string;
  cdUsuario: number;
}

interface InvitationInfo {
  cdUsuario: number;
  nome: string;
  cdAtendimento: number;
}

interface SocketContextData {
    socket: Socket | undefined,
    connected: boolean,
    receivedMessage: IMensagem | null;
    enterChatRoom: (props: IEnterChat) => void,
    novosAtendimentos: NovosAtendimentos,
    setNovosAtendimentos: Dispatch<SetStateAction<NovosAtendimentos>>,
    atendimentoFinalizado : boolean
    setAtendimentoFinalizado : Dispatch<SetStateAction<boolean>>,
    videoOffer: ISocketVideoMessage | null
    setVideoOffer: Dispatch<SetStateAction<ISocketVideoMessage | null>>,
    videoAction: IVideoAction | null,
    setVideoAction: Dispatch<SetStateAction<IVideoAction | null>>,
    sendLocalSignal: (obj : ISignal) => Promise<void>,
    receivedSignals: ISignal[],
    requestOnlineUsers: () => Promise<void>
    setReceivedSignals: (signal: any) => void;
    invitationInfo: InvitationInfo;
    setInvitationInfo: (invitationInfo: InvitationInfo) => void;
    invitationResponse: InvitationResponse | null;
    setInvitationResponse: (action: null | InvitationResponse) => void;
    onlineUsers: GetUserByIdDTO[];
}

const SocketContext = createContext<SocketContextData>({} as any);

function SocketProvider({ children } : any) {
  const [socket, setSocket] = useState<Socket>();
  const [connected, setConnected] = useState<boolean>(false);
  const [receivedMessage, setReceivedMessage] = useState<IMensagem |null>(null);
  const [novosAtendimentos, setNovosAtendimentos] =
  useState<NovosAtendimentos>({ atendimentos: [], count: 0 } as NovosAtendimentos);
  const [atendimentoFinalizado, setAtendimentoFinalizado] = useState<boolean>(false);
  const [videoOffer, setVideoOffer] = useState<ISocketVideoMessage | null>(null);
  const [videoAction, setVideoAction] = useState<IVideoAction | null>(null);
  const [receivedSignals, setReceivedSignals] = useState<ISignal[]>([]);
  const [invitationInfo, setInvitationInfo] = useState<InvitationInfo | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<GetUserByIdDTO[]>([]);
  const [invitationResponse, setInvitationResponse] = useState<InvitationResponse| null>(null);
  const { cdUsuario, associatedBusinesses } = useAuth();
  const navigate = useNavigate();

  async function checkBusinesses(cdAtendimento: number) {
    const { data } = await callNurseApi.get(`/atendimento/?cdAtendimento=${cdAtendimento}`);
    const isAssociated = associatedBusinesses.some(item =>
      item.cdEmpresa === data.atendimento.cdGrupo);

    if (!isAssociated) return;

    setNovosAtendimentos(
      ({ atendimentos, count }) =>
        ({ atendimentos: [...atendimentos, cdAtendimento], count: count + 1 }),
    );

    if (Notification.permission === 'granted' && sessionStorage.getItem('id')) {
      const notification = new Notification('BUNZL', {
        body: 'Novo atendimento em espera!',
        icon: 'https://storage.googleapis.com/call-nurse-assets/push_notification_logo.png',
      });

      notification.onclick = () => {
        navigate(`requests-form/${cdAtendimento}`, { });
        window.focus();
      };
    }
  }

  async function getOnlineUsers(usersList: IOnlineUser[]) {
    const formattdRequest = (usersList.map(item => item.cdUsuario && ({
      cdUsuario: item.cdUsuario,
    }))).filter(item => item != null);
    try {
      const { data } = await callNurseApi.post('/usuario/selectByIds', { usuarios: formattdRequest });
      setOnlineUsers(data.usuarios);
    } catch (e) {
      console.log(e);
    }
  }

  async function getInvitationInfo(cdUsuario: number, cdAtendimento: number) {
    const { data } = await callNurseApi(`/usuario/${cdUsuario}`);
    setInvitationInfo({
      nome: data.usuario.nome,
      cdUsuario,
      cdAtendimento,
    });
  }

  useEffect(() => {
    if (!socket && cdUsuario) {
      setSocket(io(variables.API_URL, { query: { cdUsuario } }));
    }
  }, [cdUsuario]);

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        setConnected(true);
        // socket acabou de se conectar
      });

      socket.on('reconnect', () => {
        setConnected(true);
        // socket acabou de se conectar
      });

      socket.on('reconnect_failed', () => {
        toast.info('Socket não conseguiu se reconectar');
      });

      socket.on('disconnect', (reason) => {
        setConnected(false);
        toast.info(`Socket desconectou: ${reason}`);
      });

      socket.on('message', (msg: IMensagem) => {
        setReceivedMessage(msg);
      });

      socket.on('novo atendimento', (cdAtendimento: number) => {
        if (!novosAtendimentos.atendimentos.includes(cdAtendimento)) {
          checkBusinesses(cdAtendimento);
        }
      });

      socket.on('end service', ({ cdCliente, cdUsuario: _cdUsuario } : {cdCliente?: number, cdUsuario: number}) => {
        if (cdCliente) {
          setAtendimentoFinalizado(true);
        }
        if (_cdUsuario !== cdUsuario) {
          setAtendimentoFinalizado(true);
        }
      });

      socket.on('video call request', (socketMessage: ISocketVideoMessage) => {
        setVideoOffer(socketMessage);
      });

      socket.on('video action notification', (action : IVideoAction) => {
        setVideoAction(action);
      });

      socket.on('signal', (signalInfo : ISignal) => {
        if (signalInfo.cdUsuario === cdUsuario) return;
        const newReceivedSignals = [...receivedSignals];
        if (signalInfo?.cdUsuario) {
          newReceivedSignals.filter(signal => signal.cdUsuario !== signalInfo.cdUsuario);
        } else {
          newReceivedSignals.filter(signal => signal.cdCliente !== signalInfo.cdCliente);
        }
        setReceivedSignals((oldReceivedSignals: any) => {
          const filtered = oldReceivedSignals.filter(signal =>
            signal.cdUsuario !== signalInfo.cdUsuario);
          return ([...filtered, signalInfo]);
        });
      });

      socket.on('new invitation', (inviteInfo: TargetedAction) => {
        if (inviteInfo.to?.cdUsuario === cdUsuario) {
          getInvitationInfo(inviteInfo.from?.cdUsuario, inviteInfo.cdAtendimento);
        }
      });

      socket.on('invitation response', (inviteInfo: InvitationResponse) => {
        if (inviteInfo.to?.cdUsuario === cdUsuario) {
          setInvitationResponse(inviteInfo);
        }
      });

      socket!.on('online users', ({ users }: {users: IOnlineUser[]}) => {
        const userList: IOnlineUser[] = users.filter(item => item.cdUsuario !== cdUsuario);
        getOnlineUsers(userList);
      });
    }
  }, [socket]);

  const enterChatRoom = ({ cdAtendimento, cdUsuario }: IEnterChat) => {
    if (socket) { socket.emit('enter room', { cdAtendimento, cdUsuario }); }
  };

  const sendLocalSignal = async (signalObject : ISignal) => {
    if (socket) {
      socket.emit('current signal', signalObject);
    }
  };

  const requestOnlineUsers = async () => {
    if (socket) {
      socket.emit('get online users');
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        connected,
        receivedMessage,
        enterChatRoom,
        novosAtendimentos,
        setNovosAtendimentos,
        atendimentoFinalizado,
        setAtendimentoFinalizado,
        videoOffer,
        setVideoOffer,
        videoAction,
        setVideoAction,
        sendLocalSignal,
        receivedSignals,
        requestOnlineUsers,
        setReceivedSignals,
        invitationInfo,
        setInvitationInfo,
        onlineUsers,
        invitationResponse,
        setInvitationResponse,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
}

function useSocket() {
  const context = useContext(SocketContext);

  return context;
}

export {
  useSocket,
  SocketProvider,
};

/* eslint-disable global-require */
const inProduction = true;

export const keySessionStorage = {
  KEY_TOKEN: '@BNZL:token',
  KEY_PERMISSIONS: '@BNZL:permissions',
  KEY_ID: 'id',
  KEY_BUSINESSES: '@BNZL:businesses',
};

const production = {
  API_URL: 'https://call-nurse-api-rsaxdr7avq-rj.a.run.app',
  IMAGES_URL: 'https://storage.googleapis.com/call-nurse-mensagens/',
};

const development = {
  API_URL: 'https://callnurse-api-teste-rsaxdr7avq-rj.a.run.app',
  IMAGES_URL: 'https://storage.googleapis.com/call-nurse-mensagens/',
};

const keyEncrypt = 'Every straight line is an arc of an infinite circle';

export const variables = inProduction ? production : development;

export const encryptor = require('simple-encryptor')(keyEncrypt);

/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
import axios from 'axios';
import { variables, keySessionStorage } from '../configuration/Constants';

const http = require('http');
const https = require('https');

export const callNurseApi = axios.create({
  timeout: 30000,

  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true }),

  maxRedirects: 10,

  maxContentLength: 50 * 1000 * 1000,

  baseURL: variables.API_URL,
});

callNurseApi.interceptors.response.use((response) => response, async (error) => {
  Promise.reject(error);
});

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

import { callNurseApi } from '../../services/api';
import { userPanelFields } from '../../utils/searchFields';

import { useAuth } from '../../hooks/useAuth';

import { Input } from '../../components/InputDown';
import { Table } from '../../components/Table/index.js';
import { Title } from '../../components/Title';

import { Content } from './styles';

 interface Usuario {
  nome: string;
  email: string;
  status: number;
  IdInterno: number;
  CaminhoImg: string;
  Empresa: string;
  cdUsuario: number;
}

export const UserPanel: React.FC = () => {
  const [data, setData] = useState<Usuario[]>([]);
  const { permissions, associatedBusinesses } = useAuth();

  const userPermissions = permissions.find(item => item.idPermissao === 'usuario');

  async function BuscarUsuarios() {
    try {
      const cdGrupo = associatedBusinesses.length === 1 ? `?cdGrupo=${associatedBusinesses[0].cdEmpresa}` : '';
      const response = await callNurseApi.get(`/usuario/${cdGrupo}`, {});

      const UsuarioFormatado = response.data.usuarios
        .map((user : Usuario) => ({ ...user, IdInterno: user.cdUsuario }));

      setData(UsuarioFormatado);
    } catch {
      toast.error('Erro ao buscar dados');
    }
  }

  useEffect(() => {
    BuscarUsuarios();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'cdUsuario',
        Filter: Input,
        filter: 'fuzzyText',
        width: 10,
      },
      {
        Header: 'Nome',
        accessor: 'nome', // accessor is the "key" in the data
        Filter: Input,
        filter: 'fuzzyText',

      },
      {
        Header: 'E-mail',
        accessor: 'email',
        Filter: Input,
        filter: 'fuzzyText',
      },
      {
        Header: 'Ação',
        accessor: 'IdInterno',
        Cell: ({ cell: { value } }: any) => (
          userPermissions.editar ? (
            <Link to={{ pathname: `/user-form/${value}` /* state: { value } */ }}>
              <button type="button">
                Editar
              </button>
            </Link>
          ) : (
            <button type="button" id="disabled">Nenhuma ação</button>
          )
        ),
        width: 40,
      },
    ],
    [],
  );
  return (
    <Content>
      <Helmet>
        <title>Bunzl | Usuários Backoffice</title>
      </Helmet>
      <Title title="Usuários" addButton={userPermissions.editar ? 'create-user' : null} />
      <Table fields={userPanelFields} columns={columns} data={data} />
    </Content>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import { callNurseApi } from '../../services/api';
import { Content, Container } from './styles';
import { Select } from '../../components/Select';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';

import { Title } from '../../components/Title';

export interface Cliente {
    IdInterno: number,
    cdEmpresa: number,
    razaoSocial?: string,
    cnpj?: string,
    cdTipoEmpresa?: number;
    tipoEmpresa: string;

}

export function ClientForm() {
  const { id } = useParams();

  const [empresaData, setEmpresaData] = useState<Cliente>({} as Cliente);

  useEffect(() => {
    async function BuscarEmpresa() {
      try {
        const response = await callNurseApi.get(`/empresa/${id}`);
        setEmpresaData((old) => ({ ...old, ...response.data.empresa }));
      } catch {
        console.warn('Erro ao buscar dados');
      }
    }

    BuscarEmpresa();
  }, []);

  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(async (data:object) => console.log('teste'), []);

  return (
    <>
      <Title title="Empresa" backButton />
      <Content>
        <h2>Visualizar</h2>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <h3 className="gray">Dados da empresa</h3>
          <div id="info-solicitacao">
            <Input
              disabled
              name="razaoSocial"
              label="Razão social"
              value={empresaData?.razaoSocial}
            />
            <Input
              disabled
              name="cnpj"
              label="CNPJ"
              value={empresaData?.cnpj}
            />
            <Input
              disabled
              name="tipoEmpresa"
              label="Tipo de empresa"
              value={empresaData?.tipoEmpresa}
            />

          </div>

        </Form>
      </Content>
    </>
  );
}
